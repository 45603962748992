console.log('BasicText')

export const basicText = [
// --------------------------------------------footer of main menu
    {   id: 0,
        en: 'Creating web since 2009',
        ru: 'Создаю web с 2009г',
    },
// --------------------------------------------top menu pops
    {   id: 1,
        en: 'Working properties',
        ru: 'Рабочие свойства',
    },
    {   id: 2,
        en: 'Startup / Partnership',
        ru: 'Стартап / Партнёрство',
    },
    {   id: 3,
        en: 'Partnership',
        ru: 'Партнёрство',
    },
    {   id: 4,
        en: 'Pay for work',
        ru: 'Оплатить работу',
    },
    {   id: 5,
        en: 'Write message',
        ru: 'Написать',
    },
// --------------------------------------------top contacts
    {   id: 6,
        data: '79530920272',
    },
    {   id: 7,
        data: 'samborsky_r',
    },
    {   id: 8,
        data: '90 552 549 1840',
    },
    {   id: 9,
        data: "samborsky.r@gmail.com",
    },
// --------------------------------------------footer
    {   id: 10,
        en: "Join, don't forget about me:",
        ru: "Подписывайтесь, чтобы не забыть:",
    },
// --------------------------------------------popup_1
    {   id: 11,
        en: "My strengths and weaknesses",
        ru: "Мои сильные и слабые стороны",
    },

    {   id: 12,
        ru: "Кропотливый / Перфекционист",
        en: "Painstaking / Perfectionist",
    },
    {   id: 13,
        ru: "Светлая сторона медали: Вникаю и учитываю в работе самые мелкие детали задачи. Благодаря этому всё получается очень качественно и продумано.___Тёмная сторона медали: Могу увлечься маловажными аспектами задачи и потратить больше времени чем с практичной точки зрения.",
        en: "The bright side of the coin: I delve into and take into account the smallest details of the task in my work. Thanks to this, everything turns out to be very high quality and well thought out.___The dark side of the coin: I can get carried away with unimportant aspects of the task and spend more time than from a practical point of view.",
    },
    {   id: 14,
        ru: "",
        en: "",
    },

    {   id: 15,
        ru: "Аналитический ум",
        en: "Analytical mind",
    },
    {   id: 16,
        ru: "Светлая сторона медали: Особо эффективен и доволен когда занимаюсь большими, сложными задачами на протяжении дней или недель.___Тёмная сторона медали: Плохо работается и скучаю когда есть огромный стек простых, мелких задач на каждый день.",
        en: "The bright side of the coin: I am especially effective and happy when I deal with large, complex tasks over the course of days or weeks.___The dark side of the coin: I work poorly and get bored when there is a huge stack of simple, small tasks for every day.",
    },
    {   id: 17,
        ru: "",
        en: "",
    },
    {   id: 18,
        ru: "",
        en: "",
    },
    {   id: 19,
        ru: "",
        en: "",
    },

    {   id: 20,
        ru: "Инициативность",
        en: "Initiative",
    },
    {   id: 21,
        ru: "Светлая сторона медали: Вникаю в бизнес процессы проекта и часто презентую команде идеи по улучшению или нововведениям. Вношу правки не дожидаясь неделями ответственного.___Тёмная сторона медали: Парой трачу своё профильное время на то, чем по идеи должны заниматься другие.",
        en: "The bright side of the coin: I delve into the business processes of the project and often present ideas for improvement or innovation to the team. I make edits without waiting weeks for the person in charge.___The dark side of the coin: Sometimes I spend my specialized time on what I think others should be doing.",
    },
    {   id: 22,
        ru: "",
        en: "",
    },
    {   id: 23,
        ru: "",
        en: "",
    },
// --------------------------------------------popup_3
    {   id: 24,
        ru: "Стартап? Могу работать дешевле, если мы партнёры",
        en: "Startup? I can work cheaper if we are partners",
    },

    {   id: 25,
        ru: "До 25% за отложенную премию",
        en: "Up to 25% by deferred premium",
    },
    {   id: 26,
        ru: "Если ваш стартап покажется мне действительно классным и перспективным, то я могу работать дешевле, из расчёта что запустив проект - вы с лихвой покроете мне бесплатно отработанные часы.",
        en: "If your startup seems really cool and promising to me, then I can work cheaper, on the basis that by launching the project, you will more than cover the hours I worked for free.",
    },
    {   id: 27,
        ru: "",
        en: "",
    },

    {   id: 28,
        ru: "До 50% если совладелец",
        en: "Up to 50% if co-owner",
    },
    {   id: 29,
        ru: "Если ваш стартап покажется мне действительно классным и перспективным, то я могу работать дешевле, из расчёта что я буду совладельцем этого проекта.",
        en: "If your startup seems really cool and promising to me, then I can work cheaper, assuming that I will be a co-owner of this project.",
    },

    {   id: 30,
        ru: "До 65% если совладелец + онлайн платежи",
        en: "Up to 65% if co-owner + online payments",
    },
    {   id: 31,
        ru: "Если ваш стартап покажется мне действительно классным и перспективным, то я могу работать дешевле, из расчёта что я буду совладельцем этого проекта. Также большим плюсом будет наличие в проекте онлайн манетизации на стартовых этапах среди основных механик (не с рекламы).",
        en: "If your startup seems really cool and promising to me, then I can work cheaper, assuming that I will be a co-owner of this project. Also, a big plus will be the presence of online manetization in the project at the starting stages among the main mechanics (not from advertising).",
    },
// --------------------------------------------popup_2
    {   id: 32,
        ru: "Стартап? Нужно экономить?",
        en: "Startup? Need to save money?",
    },
    {   id: 33,
        ru: "Если вы ищите специалиста в команду стартапа и у вас недостаточно средств чтобы нанять профессионала на работу, то я с удовольствием рассмотрю ваше предложение со ставкой ниже ожидаемой.",
        en: "If you are looking for a specialist for a startup team and you do not have enough funds to hire a professional, then I will be happy to consider your offer at a rate lower than what I work for.",
    },
    {   id: 34,
        ru: "Конечно же проект должен быть классным и перспективным, а меня нужно будет рассмотреть на роль партнёра - чтобы однажды моя работа окупилась. Также большим плюсом будет наличие в проекте онлайн монетизации среди основных механик (не с рекламы).",
        en: "Of course, the project must be cool and promising, and I will need to be considered for the role of a partner - so that one day my work will pay off. Also, a big plus will be the presence of online monetization among the main mechanics in the project (not advertising).",
    },
// --------------------------------------------popup_4
    {   id: 35,
        ru: "Оплата работы через тут :)",
        en: "Payment for my work",
    },
    {   id: 36,
        ru: "Навряд ли когда-то, кто-то этим воспользуется, но так хотелось это реализовать!",
        en: "It’s unlikely that anyone will ever use this, but I really wanted to implement it!",
    },
    {   id: 37,
        ru: "Представьтесь",
        en: "Introduce yourself",
    },
    {   id: 38,
        ru: "Телефон или Email",
        en: "Phone or Email",
    },
    {   id: 39,
        ru: "Что вы оплачиваете (кратко)",
        en: "What do you pay for (briefly)",
    },
    {   id: 40,
        ru: "Сумма перевода в ₽",
        en: "Transfer amount in $",
    },
    {   id: 41,
        ru: "Перейти к оплате",
        en: "Go to payment",
    },
    // texts of "waiting" and "error" below in the text: № 50 & 51
// --------------------------------------------popup_5
    {   id: 42,
        ru: "Оставить сообщение",
        en: "Send message",
    },
    {   id: 43,
        ru: "Опишите ниже какой вопрос вас интересует, оставьте контактные данные и я свяжусь с вами в ближайшее время. Ваше сообщение будет отправлено на почту: ",
        en: "Describe below what question interests you, leave your contact information and i will contact you as soon as possible. Your message will be sent to: ",
    },
    {   id: 44,
        ru: "Что вас интересует?",
        en: "What are you interested in?",
    },
    {   id: 45,
        ru: "Прикрепить",
        en: "Attach",
    },
    {   id: 46,
        ru: "Выберите файл",
        en: "Select file",
    },
    {   id: 47,
        ru: "Отправить",
        en: "Send",
    },
    {   id: 48,
        ru: "Ваше сообщение успешно отправлено! Пожалуйста ожидайте, я скоро свяжусь с вами.",
        en: "Your message has been sent successfully! Please wait, i will contact you soon.",
    },
    {   id: 49,
        ru: "Произошла ошибка! Сообщение не отправлено. Пожалуйста попробуйте ещё раз или напишите нам на почту: ",
        en: "An error has occurred! Message not sent. Please try again or write to us by email: ",
    },
// --------------------------------------------popup_4 "waiting" and "error"
    {   id: 50,
        ru: "Оплата успешно сформирована и открыта в соседней вкладке. Вы можете перейти для оплаты, а эту форму закрыть.",
        en: "The payment has been successfully generated and opened in a next tab. You can close this form and go to payment.",
    },
    {   id: 51,
        ru: "Произошла ошибка! Перейти к оплате не получается. Пожалуйста попробуйте ещё раз или чуть позже.",
        en: "An error has occurred! I can't proceed to payment. Please try again or a little later.",
    },
// --------------------------------------------copyright in footer
    {   id: 52,
        ru: "Самборский Роман",
        en: "Samborsky Roman",
    },
]


































